import React, { Component } from 'react'
import './homepage.css';
import './allEggs.css';

class AllEggs extends Component {
  
  constructor(props) {
    super(props);
   
    this.encode = this.encode.bind(this);
  }

  encode(egg) {
    const enc = encodeURIComponent(egg.value);//.replace("%", "%25"));
    return enc;  
  }
 
  render() {
    return (
      <div className="homepage-full">
        <div className="homepage-link-container" style={{marginTop: "-3.2%"}}>
          <h2 className="homepage-link-text"><a className="homepage-link" href="/">Gudegle</a></h2>
        </div>
        <div className="eggs-container grid-item">
          <h2 className="all-eggs-text">All Eggs</h2>
          <ol type="1">
            {this.props.searchKeys.map((egg, i) => 
              <li><a href={"/egg/" + this.encode(egg)}>{egg.value}</a></li>
            )}
          </ol>
        </div>
      </div> 
    )
  } 
}

export default AllEggs;
