import React from "react";
import { useParams, useHistory } from "react-router-dom";
import GudetamaList from '../GudetamaList.js';
import SearchBox from './searchBox';
import Tree from './tree';

import './homepage.css';

const HomepageTree = (props) => {

  let { searchKeys, searchEgg } = props;
  let { eggName } = useParams();
  let decodedEggName = decodeURIComponent(eggName);
  return (
    <div className="homepage-full">
      <div className="homepage-tree-link-container">
        <h2 className="homepage-tree-link-text"><a className="homepage-link" href="/">Gudegle</a></h2>
      </div>
      <div className="homepage-tree-content grid-item">
        <div className="homepage-tree-search-box-container grid-item">
          <SearchBox
            className="search-bar-small grid-item"
            searchKeys={searchKeys} 
            searchEgg={searchEgg} 
          />
        </div>
        <Tree
          className="tree-view-main grid-item"
          eggName={decodedEggName}
        />
      </div>
    </div> 
  )
}

export default HomepageTree;
