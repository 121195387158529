import React, { Component } from 'react'
import './treeRow.css'

class TreeRow extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      egg: this.props.egg,
      indent: this.props.indent 
    };
  }

  render() {
    let egg = this.state.egg;
    let divStyle = {marginLeft: `${this.state.indent * 3}vw`};
    return (
      <div className="row-container">
        <span className="row-container-quantity">{egg.quantity}</span>
        <span className="row-container-name">{egg.name}</span>
        <span className="row-container-number">#{egg.number}</span>
        <span className="row-container-cooking-info">({egg.cooking_device}, {egg.cooking_time})</span>
      </div>
    )
  }
}

export default TreeRow;
