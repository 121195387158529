import React, { Component } from 'react'
import ReactSearchBox from 'react-search-box'
import './searchBox.css'

class SearchBar extends Component {
   constructor(props) {
    super(props);
    this.state = {
      searchKeys: this.props.searchKeys
    };
  }

  render() {
    return (
      <div className="search-box">
        <ReactSearchBox
          placeholder="Search for a Gudetama by name"
          data={this.state.searchKeys}
          onSelect={record => {console.log(record);this.props.searchEgg(record)}}
          onFocus={() => {
            //console.log('This function is called when is focussed')
          }}
//          onChange={value => console.log(value)}
          fuseConfigs={{
            threshold: 0.05,
          }}
        />     
      </div>
    )
  }
}

export default SearchBar;
