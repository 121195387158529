import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import Homepage from './components/homepage';
import HomepageTree from './components/homepageTree';
import AllEggs from './components/allEggs';
import GudetamaList from './GudetamaList.js';

import './App.css';

class App extends Component {
 
  constructor(props) {
    super(props);
    this.state = {
      searchKeys: this.normalizeEggArray(GudetamaList),
      egg: ""
    };

    this.normalizeEggArray = this.normalizeEggArray.bind(this);
    this.searchEgg = this.searchEgg.bind(this);
  }

  normalizeEggArray(arr) {
    let ret = [];
    for (let i = 0; i < arr.length; i++) {
      let underscore = arr[i].split(" ").join("_");
      ret.push({key: underscore, value: arr[i]});
    }
    return ret;
  }

  searchEgg(record) {
    this.setState({egg: record.value})
    this.props.history.push('/egg/' + encodeURIComponent(record.value.replace("%", "%25")));  
  }

  componentWillReceiveProps(props) {
      let name = props.location.pathname;
      this.setState({egg: (5, name.length)});
  }
 
  render() { 
    return (
      <div>
        <Switch>
          <Route 
            exact path='/'
            render={(props) => (
              <Homepage 
                {...props} 
                searchKeys={this.state.searchKeys} 
                searchEgg={this.searchEgg} 
              />
            )}
          />
          <Route 
            exact path='/egg/:eggName'
            render={(props) => (
              <HomepageTree 
                {...props}
                key={this.state.egg} 
                searchKeys={this.state.searchKeys}
                searchEgg={this.searchEgg}
              />
            )}
          />
          <Route 
            exact path='/eggs'
            render={(props) => (
              <AllEggs
                {...props}
                searchKeys={this.state.searchKeys}
              />
            )}
          />
        </Switch>
      </div>
    ) 
  }
}

export default withRouter(App);
