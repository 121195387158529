import React, { Component } from 'react'
import { Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import TreeRow from './treeRow';
import './treeNode.css';

class TreeNode extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      node: this.props.node,
      dropped: false,
      indent: (this.props.indent ? this.props.indent : 0)
    }; 
    this.flipChevron = this.flipChevron.bind(this);
  }

  flipChevron() {
    this.setState({dropped: !this.state.dropped});
  }

  render() {
    let divStyle = {marginLeft: `${this.state.indent * 2}vw`};
    let arrowDir = (this.state.dropped ? faChevronDown : faChevronRight);
    if (this.state.node.obj_children.length === 0) {
      return (
        <div className="subtree-container" style={divStyle}>
          <div className="row-container">
            <FontAwesomeIcon onClick={this.flipChevron} className="row-dropdown-placeholder" icon={ arrowDir } /> 
            <TreeRow egg={this.state.node} indent={this.state.indent} /> 
          </div>
        </div>
      )
    } else {
      return (
        <Accordion>
          <div className="subtree-container" style={divStyle}>
            <div className="row-container">
              <Accordion.Toggle className="row-dropdown" as={"div"} variant="link" eventKey="0">
                <FontAwesomeIcon onClick={this.flipChevron} className="row-dropdown" icon={ arrowDir } /> 
              </Accordion.Toggle>
              <TreeRow egg={this.state.node} indent={this.state.indent} /> 
            </div>
            <Accordion.Collapse eventKey="0">
              <div>
                {this.state.node.obj_children.map((child, i) => 
                  <TreeNode key={i} node={child} indent={this.state.indent + 1} />
                )}
              </div>
            </Accordion.Collapse>
          </div>
        </Accordion>
      )
    }
  }
}

export default TreeNode;
