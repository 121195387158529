import React, { Component } from 'react'

import './treeParent.css';

class TreeParent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      egg: this.props.egg,
    };
    this.clipTrailing = this.clipTrailing.bind(this);
    this.formatCooking = this.formatCooking.bind(this);
  }

  clipTrailing(url) {
    if (url === "") {
      return "https://static.wikia.nocookie.net/gudetama-tap/images/2/26/Egg.png"
    }
    let re = /.+\.(png|PNG)/;
    let ex = re.exec(url);
    return ex[0];
  }

  formatCooking() {
    if (this.state.egg.type !== "") {
      return <p className="tree-parent-cooking-info">{this.state.egg.cooking_device}, {this.state.egg.cooking_time} ({this.state.egg.type})</p>
    } else {
      return <p className="tree-parent-cooking-info">{this.state.egg.cooking_device}, {this.state.egg.cooking_time}</p>
    }
  }

  render() {
    return (
      <div className="tree-parent-container">
        <div className="tree-parent-image-container">
          <img 
            src={this.clipTrailing(this.state.egg.image)}
            alt="new"
            className="tree-parent-image"
          />
        </div>
        <h1 className="tree-parent-name">#{this.state.egg.number} {this.state.egg.name}</h1>
        {this.formatCooking()}
      </div>
    )
  }
}

export default TreeParent;
