import React, { Component } from 'react'
import { withRouter } from 'react-router';
import SearchBox from './searchBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip';
import './homepage.css';

class Homepage extends Component {
  
  constructor(props) {
    super(props);
   
    this.randomize = this.randomize.bind(this);
  }

  randomize() {
    const randomElement = this.props.searchKeys[Math.floor(Math.random() * this.props.searchKeys.length)];
    const enc = encodeURIComponent(randomElement.value.replace("%", "%25"));
    return enc;  
  }
  
  render() {
    return (
      <div className="homepage-full">
        <div className="homepage-link-container">
          <h2 className="homepage-link-text"><a className="homepage-link" href="/">Gudegle</a></h2>
        </div>
        <div className="homepage-tooltip-container">
          <FontAwesomeIcon data-for='getContent' data-tip className="homepage-info-tooltip" icon={faInfoCircle} />
          <ReactTooltip id='getContent' getContent={() => "Gudgle is a resource for mobile game Gudetama Tap to easily see recipe trees."} />
        </div>
        <div className="homepage-content grid-item">
          <div className="search-box-container grid-item">
            <h2 className="gudegle-title">Gudegle</h2>
          </div>
          <div className="search-box-container grid-item">
            <SearchBox
              className="search-bar-small grid-item"
              searchKeys={this.props.searchKeys} 
              searchEgg={this.props.searchEgg} 
            />
          </div>
          <div className="list-and-random-container grid-item">
            <p className="list-and-random-text"><a href="/eggs">See All Eggs</a></p><p className="list-and-random-text"><a href={"/egg/" + this.randomize()}>Pick a Random Egg</a></p>
          </div>
        </div>
      </div> 
    )
  } 
}

export default withRouter(Homepage);
