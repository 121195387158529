import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Accordion } from "react-bootstrap";
import TreeParent from './treeParent';
import TreeNode from './treeNode';
import GudetamaFull from '../GudetamaFull.js'
import './tree.css'

class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      unlocks: [],
      treeData: null
    }
    this.normalizeEggName = this.normalizeEggName.bind(this);
    this.getUnlocks = this.getUnlocks.bind(this);
    this.constructTree = this.constructTree.bind(this);
    this.constructTreeHelper = this.constructTreeHelper.bind(this);
  }

  componentDidMount() {
    let prnt = this.newRootNode(this.props.eggName)
    this.constructTree(prnt); 
    this.getUnlocks(this.props.eggName);
  }

  normalizeEggName(name) {
    return name.split("_").join(" ");
  }

  getUnlocks(name) {
    let unlocks = [];
    for (let key in GudetamaFull) {
      for (let i = 0; i < GudetamaFull[key].recipes_required.length; i++) {
        let child = GudetamaFull[key].recipes_required[i];
        if (child.name === name) {
          unlocks.push({
            name: name,
            quantity: child.quantity,
            parentInfo: GudetamaFull[key],
          });
        }
      }
    }
    this.setState({unlocks: unlocks});
  }

  newRootNode(underName) {
    let name = this.normalizeEggName(underName);
    if (!(name in GudetamaFull)) {
      this.setState({error: true});
      return;
    }
    let obj = GudetamaFull[name];
    obj.quantity = 1;
    obj.obj_parent = null;
    obj.obj_children = [];
    return obj 
  }

  constructTree(prnt) {
    for (let i = 0; i < prnt.recipes_required.length; i++) {
      let child = GudetamaFull[prnt.recipes_required[i].name]; 
      if (child == null) {
        this.setState({treeData: prnt});
        return;
      }
      child.obj_children = [];
      child.quantity = prnt.recipes_required[i].quantity;

      prnt.obj_children.push(child);
      this.constructTreeHelper(child, prnt.recipes_required[i].quantity);
    }
    this.setState({treeData: prnt});
  }

  constructTreeHelper(prnt, multiplier) {
     if (prnt.recipes_required.length === 0) {
       return;
     }
     for (let i = 0; i < prnt.recipes_required.length; i++) {
       let quantity = prnt.recipes_required[i].quantity
       let child = GudetamaFull[prnt.recipes_required[i].name];
       if (child == undefined) {
          console.log("died on:");
          console.log(prnt);
          console.log(child);
       }
       console.log(child);
       child.obj_children = [];
       child.quantity = quantity;

       prnt.obj_children.push(child);
       this.constructTreeHelper(child, quantity);
     } 
  }

  render() {
    if (this.state.error) {
      return <h3>There was some error~</h3>
    }
    if (!this.state.treeData) {
      return <div></div>
    } else {
      return (
        <div>
          <TreeParent egg={this.state.treeData} />
          <Accordion>
            {this.state.treeData.other_required.length > 0 && 
              <div className="required-recipes-container">
                <Accordion.Toggle className="row-dropdown" as={"div"} variant="link" eventKey="0">
                  <h3 className="required-recipes-header-text">Required to Unlock<span id="click-to-show">(Click to Show)</span></h3>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div>
                    {this.state.treeData.other_required.length > 0 &&
                      this.state.treeData.other_required.map((child, i) => 
                        <p>{child}</p>
                      )
                    }
                  </div>
                </Accordion.Collapse>
              </div>
            }
          </Accordion>
          <Accordion>
            {this.state.treeData.obj_children.length > 0 && 
              <div className="required-recipes-container">
                <Accordion.Toggle className="row-dropdown" as={"div"} variant="link" eventKey="0">
                  <h3 className="required-recipes-header-text">Recipes Required <span id="click-to-show">(Click to Show)</span></h3>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div>
                    {this.state.treeData.obj_children.length > 0 &&
                      this.state.treeData.obj_children.map((child, i) => 
                        <TreeNode key={i} node={child} indent={this.state.indent + 1} />
                      )
                    }
                  </div>
                </Accordion.Collapse>
              </div>
            }
          </Accordion>
          <Accordion>
            {this.state.treeData.ramens.length > 0 && 
              <div className="egg-unlocks-container">
                <Accordion.Toggle className="row-dropdown" as={"div"} variant="link" eventKey="1">
                  <h3 className="required-recipes-header-text">Ramens <span id="click-to-show">(Click to Show)</span></h3>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div className="egg-unlocks-items-container">
                    {this.state.treeData.ramens.map((ramen, i) =>
                      <p key={i} className="testing">{ramen}</p>
                    )}
                  </div>
                </Accordion.Collapse>
              </div>
            }
          </Accordion>
          <Accordion>
            {console.log(this.state.treeData)}
            {this.state.treeData.eggrapons.length > 0 && 
              <div className="egg-unlocks-container">
                <Accordion.Toggle className="row-dropdown" as={"div"} variant="link" eventKey="1">
                  <h3 className="required-recipes-header-text">Eggrapons <span id="click-to-show">(Click to Show)</span></h3>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div className="egg-unlocks-items-container">
                    {this.state.treeData.eggrapons.map((eggrapon, i) =>
                      <p key={i}>{eggrapon}</p>
                    )}
                  </div>
                </Accordion.Collapse>
              </div>
            }
          </Accordion>
          <Accordion>
            {this.state.unlocks.length > 0 && 
              <div className="egg-unlocks-container">
                <Accordion.Toggle className="row-dropdown" as={"div"} variant="link" eventKey="1">
                  <h3 className="required-recipes-header-text">Unlocks <span id="click-to-show">(Click to Show)</span></h3>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <div className="egg-unlocks-items-container">
                    {this.state.unlocks.map((child, i) =>
                      <p key={i}>Need {child.quantity} {child.name} to unlock <a href={`/egg/${child.parentInfo.name}`}> {child.parentInfo.name}</a></p>
                    )
                    }
                  </div>
                </Accordion.Collapse>
              </div>
            }
          </Accordion>
        </div>
      )  
    }
  }
}

export default Tree;
